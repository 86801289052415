import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import { Col, Card } from 'react-bootstrap';
import Img from 'gatsby-image';
import { TimelineLite } from 'gsap';
import './_employeecards.scss';
import ReadMore from '../../../static/assets/Readmore.svg';

const kebabCase = require('lodash/kebabCase');

class EmployeeCards extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.drawArrow();
  }

  drawArrow = () => {
    const tl = new TimelineLite();
    tl.from('.drawIt', 2, { scaleX: 0, transformOrigin: '0% 100%' });
  };

  render() {
    const { employee } = this.props;
    return employee.map((emp, index) => (
      <Col xs={10} md={5} lg={4} className="py-3" key={String(index)}>
        <Card className="h-100 rounded">
          <div
            style={{
              position: 'relative',
            }}
          >
            <Img
              className="rounded"
              fluid={emp.node.frontmatter.profile_pic.childImageSharp.fluid}
            />

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                backgroundImage: 'linear-gradient(rgba(6,39,64,.02), rgba(6,39,68,1))',
              }}
            >
              <div className="d-none d-lg-block">
                <p
                  className="text-center m-0 pt-5 px-1 px-lg-3"
                  style={{
                    color: '#fff',
                    fontSize: '13px',
                    lineHeight: '150%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {emp.node.frontmatter.description}
                </p>
              </div>
              <div className="d-lg-none d-block">
                <p
                  className="text-center m-0 pt-5 px-2 px-lg-3"
                  style={{
                    color: '#fff',
                    fontSize: '13px',
                    lineHeight: '150%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {emp.node.frontmatter.description}
                </p>
              </div>

              <p
                className="text-center py-1 py-lg-2 mb-0 px-2 mb-lg-2"
                style={{ color: '#57B7F9', fontSize: '12px', lineHeight: '150%' }}
              >
                {emp.node.frontmatter.name}, {emp.node.frontmatter.post},{' '}
                {emp.node.frontmatter.place}
              </p>
            </div>
          </div>
          <Link
            to={`employee-testimonials/${kebabCase(emp.node.frontmatter.name)}`}
            className="text-appcolor"
          >
            <Card.Body className="py-3 px-auto shadow employee-card-body">
              <div className="d-flex justify-content-center">
                <p className="m-0 text-appcolor text-semibold" style={{ fontSize: '0.9rem' }}>
                  Read More <ReadMore className="arrow-translate" height="12px" width="40px" />
                </p>
              </div>
            </Card.Body>
          </Link>
        </Card>
      </Col>
    ));
  }
}

export default EmployeeCards;
