import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite, Power1 } from 'gsap';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import EmployeeCards from '../components/EmployeeCards';
import * as globalAnimation from '../animations/globalAnimation';

export default class ourMission extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    this.tl.staggerFrom(
      '.employee',
      0.5,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    );
  }

  render() {
    const {
      data: {
        allMarkdownRemark: { edges },
      },
    } = this.props;

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Employee Testimonials" />
        <Container fluid="true">
          <Row>
            <Col xs={12} lg={12}>
              <div className="main_wrapper min-vh-100">
                <Row>
                  <Col>
                    <div className="wrapper">
                      <div className="page_heading_section text-center">
                        <h3 className="page_heading text-uppercase d-lg-block d-none title">
                          Employee Testimonials
                        </h3>
                        <h4 className="text-uppercase page_heading_child subtitle">
                          Employee Testimonials
                        </h4>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Container>
                  <Row className="employee justify-content-center pb-3">
                    <EmployeeCards employee={edges} />
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            name
            post
            place
            description
            slug
            profile_pic {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
